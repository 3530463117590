import React, { ReactNode } from "react";
import MomentUtils from "@date-io/moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { ColorPalette } from "../constants/ColorPalette";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#101924",
    },
    secondary: {
      main: "#7486aa",
    },
    error: {
      main: "#e85347",
    },
    success: {
      main: "#39cc4d",
    },
    background: {
      default: ColorPalette.ExtraGray,
    },
  },
  typography: {
    fontFamily: "Rubik, Arial, sans-serif",
  },
});

interface Props {
  readonly children: ReactNode;
}

export function MaterialContainer({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        {children}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
