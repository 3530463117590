import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { UserProps } from "../api/user/UserDTO";
import { AppStoreState } from "../store/RootReducer";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const userReducerPersistConfig: Partial<PersistConfig<UserReducerState>> = {
  whitelist: [],
};

interface SetUserMeta {
  readonly list: UserProps[];
}

enum ReducerActions {
  SetUser = "User/SetUser",
}

export interface UserReducerState {
  readonly user?: UserProps;
}

function getState(): UserReducerState {
  return {
    user: {
      id: 1,
      name: "Test",
      surname: "Testov",
      position: "Administrator",
    },
  };
}

/** ==================
 * Reducers
 * ================== */
export const userReducer = createRootReducer<UserReducerState>(
  getState(),

  createReducer([ReducerActions.SetUser], (state, { meta }) => update(state, { user: meta.user })),
);

/** ==================
 * Selectors
 * ================== */
export const userSelector = ({ user }: AppStoreState): UserProps | undefined => user.user;

/** ==================
 * Actions
 * ================== */
export const setUser = (meta: SetUserMeta): PerformAction<SetUserMeta> => ({
  meta,
  type: ReducerActions.SetUser,
});
