import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";

import { AppMenuItem, AppMenuItemProps } from "./AppMenuItem";
import { changeSidebarState } from "../../reducers/AppReducer";

export function AppMenuItemWrapper(props: Omit<AppMenuItemProps, "onClick">) {
  const dispatch = useDispatch();

  const isSmall = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));

  return (
    <AppMenuItem
      {...props}
      onClick={() => {
        if (isSmall) {
          dispatch(changeSidebarState({ state: false }));
        }
      }}
    />
  );
}
