import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: { fontSize: theme.typography.pxToRem(20) },
}));

export function ClientsIcon() {
  const classes = useStyle();

  return (
    <SvgIcon classes={classes} viewBox="0 0 20 20">
      <path d="M1.66699 18.3335C1.66699 16.5654 2.36937 14.8697 3.61961 13.6195C4.86986 12.3692 6.56555 11.6668 8.33366 11.6668C10.1018 11.6668 11.7975 12.3692 13.0477 13.6195C14.2979 14.8697 15.0003 16.5654 15.0003 18.3335H13.3337C13.3337 17.0074 12.8069 15.7356 11.8692 14.798C10.9315 13.8603 9.65974 13.3335 8.33366 13.3335C7.00758 13.3335 5.73581 13.8603 4.79813 14.798C3.86044 15.7356 3.33366 17.0074 3.33366 18.3335H1.66699ZM8.33366 10.8335C5.57116 10.8335 3.33366 8.596 3.33366 5.8335C3.33366 3.071 5.57116 0.833496 8.33366 0.833496C11.0962 0.833496 13.3337 3.071 13.3337 5.8335C13.3337 8.596 11.0962 10.8335 8.33366 10.8335ZM8.33366 9.16683C10.1753 9.16683 11.667 7.67516 11.667 5.8335C11.667 3.99183 10.1753 2.50016 8.33366 2.50016C6.49199 2.50016 5.00033 3.99183 5.00033 5.8335C5.00033 7.67516 6.49199 9.16683 8.33366 9.16683ZM15.237 12.2527C16.4081 12.7801 17.402 13.6345 18.0991 14.7133C18.7963 15.792 19.1671 17.0491 19.167 18.3335H17.5003C17.5005 17.3701 17.2225 16.4272 16.6996 15.6181C16.1767 14.8091 15.4312 14.1682 14.5528 13.7727L15.2362 12.2527H15.237ZM14.6637 2.84433C15.5033 3.19041 16.2211 3.77811 16.7262 4.53285C17.2312 5.2876 17.5007 6.17536 17.5003 7.0835C17.5007 8.22711 17.0734 9.32952 16.3024 10.1742C15.5315 11.0189 14.4726 11.5447 13.3337 11.6485V9.971C13.9511 9.88256 14.5239 9.59851 14.9681 9.16054C15.4122 8.72258 15.7042 8.15376 15.8013 7.53761C15.8983 6.92145 15.7953 6.29041 15.5073 5.73712C15.2193 5.18384 14.7615 4.73745 14.2012 4.4635L14.6637 2.84433Z" />
    </SvgIcon>
  );
}
