import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    fontSize: "20px",
  },
});

export function TransactionIcon() {
  const classes = useStyle();

  return (
    <SvgIcon classes={classes} viewBox="0 0 20 20">
      <path d="M17.4997 3.33317V15.6007C17.4998 15.6815 17.4763 15.7607 17.4323 15.8284C17.3882 15.8962 17.3253 15.9497 17.2513 15.9823L9.99967 19.1915L2.74801 15.9832C2.67379 15.9504 2.61075 15.8967 2.56664 15.8286C2.52252 15.7605 2.49925 15.681 2.49967 15.5998V3.33317H0.833008V1.6665H19.1663V3.33317H17.4997ZM4.16634 3.33317V14.7873L9.99967 17.3707L15.833 14.7873V3.33317H4.16634ZM6.66634 6.6665H13.333V8.33317H6.66634V6.6665ZM6.66634 9.99984H13.333V11.6665H6.66634V9.99984Z" />
    </SvgIcon>
  );
}
