import React, { ReactNode } from "react";

import { UserProps } from "../api/user/UserDTO";
import { userSelector } from "../reducers/UserReducer";
import { UserProvider } from "../api/user/UserDataContext";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";

interface Props {
  readonly children: ReactNode;
}

export function UserContainer({ children }: Props) {
  const user = useShallowEqualSelector(userSelector);
  const token = useShallowEqualSelector(() => "");

  return <UserProvider data={{ user: user || ({} as UserProps), token }}>{children}</UserProvider>;
}
