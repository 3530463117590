import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    fontSize: "20px",
  },
});

export function NewsIcon() {
  const classes = useStyle();

  return (
    <SvgIcon classes={classes} viewBox="0 0 20 20">
      <path d="M16.6667 18.3332H3.33333C3.11232 18.3332 2.90036 18.2454 2.74408 18.0891C2.5878 17.9328 2.5 17.7209 2.5 17.4998V2.49984C2.5 2.27882 2.5878 2.06686 2.74408 1.91058C2.90036 1.7543 3.11232 1.6665 3.33333 1.6665H16.6667C16.8877 1.6665 17.0996 1.7543 17.2559 1.91058C17.4122 2.06686 17.5 2.27882 17.5 2.49984V17.4998C17.5 17.7209 17.4122 17.9328 17.2559 18.0891C17.0996 18.2454 16.8877 18.3332 16.6667 18.3332ZM15.8333 16.6665V3.33317H4.16667V16.6665H15.8333ZM6.66667 7.49984H13.3333V9.1665H6.66667V7.49984ZM6.66667 10.8332H13.3333V12.4998H6.66667V10.8332Z" />
    </SvgIcon>
  );
}
