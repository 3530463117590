import React from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@material-ui/core";

import { MenuIcon } from "../icons/MenuIcon";
import { changeSidebarState } from "../../reducers/AppReducer";

export function AppMenuButtonWrapper() {
  const dispatch = useDispatch();

  return (
    <IconButton onClick={() => dispatch(changeSidebarState({ state: true }))}>
      <MenuIcon />
    </IconButton>
  );
}
