import React, { ReactElement, ReactNode, useContext, useMemo } from "react";

import { AppEnvironment } from "./AppDTO";
import { HOST } from "../constants/ApiConstants";
import { TokenProps } from "./auth/AuthDTO";

export interface ApiContextDataProps {
  readonly token?: TokenProps;
  readonly onUpdateToken?: () => void;
  readonly environment: AppEnvironment;
}

export interface ApiContextProps {
  readonly host: string;
  readonly token?: TokenProps;
  readonly onUpdateToken?: () => void;
  readonly environment: AppEnvironment;
}

export interface ApiProviderProps {
  readonly children: ReactNode;
  readonly data?: ApiContextDataProps;
}

function createContentValue(apiData: ApiContextDataProps): ApiContextProps {
  return {
    ...apiData,
    host: HOST,
  };
}

export const ApiContext = React.createContext<ApiContextProps>(
  createContentValue({
    environment: AppEnvironment.Production,
  }),
);

export function ApiProvider({
  data = {} as ApiContextDataProps,
  ...props
}: ApiProviderProps): ReactElement<object> {
  const value = useMemo(() => createContentValue(data), [data]);

  return <ApiContext.Provider {...props} value={value} />;
}

export function useApiBase(): ApiContextProps {
  return useContext(ApiContext);
}
