import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";
import { ColorPalette } from "../../constants/ColorPalette";

interface StylesProps {
  readonly size: number;
  readonly color: ColorPalette;
}

const useStyle = makeStyles((theme) => ({
  root: {
    color: (props: StylesProps) => props.color,
    fontSize: (props: StylesProps) => theme.typography.pxToRem(props.size),
  },
}));

interface Props {
  readonly size?: number;
  readonly color?: ColorPalette;
}

export function MenuIcon({ size = 20, color = ColorPalette.Primary }: Props) {
  const classes = useStyle({ size, color });

  return (
    <SvgIcon classes={classes} viewBox="0 0 24 24">
      <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
    </SvgIcon>
  );
}
