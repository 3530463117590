import cx from "classnames";
import React, { forwardRef, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, NavLinkProps } from "react-router-dom";
import { ListItemIcon, MenuItem, MenuItemProps } from "@material-ui/core";

import { ColorPalette } from "../../constants/ColorPalette";

const useStyles = makeStyles((theme) => ({
  root: {
    letterSpacing: "0.15px",
    color: ColorPalette.Secondary,
    height: theme.typography.pxToRem(48),
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(20),
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
  },
  active: { color: ColorPalette.Success },
  icon: { color: "inherit", minWidth: theme.typography.pxToRem(30) },
}));

const NavLinkRef = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

NavLinkRef.displayName = "NavLinkRef";

type MenuItemLinkProps = NavLinkProps & MenuItemProps<"li", { button?: true }>;

export interface AppMenuItemProps extends MenuItemLinkProps {
  readonly title: string;
  readonly leftIcon?: ReactNode;
}

function AppMenuItem({ title, leftIcon, className, ...props }: AppMenuItemProps) {
  const classes = useStyles();

  return (
    <MenuItem
      component={NavLinkRef}
      activeClassName={classes.active}
      className={cx(classes.root, className)}
      {...props}
    >
      <ListItemIcon className={classes.icon}>{leftIcon}</ListItemIcon>

      {title}
    </MenuItem>
  );
}

AppMenuItem.displayName = "AppMenuItem";

export { AppMenuItem };
