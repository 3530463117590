import { update } from "immupdate";
import { camelCase, isArray, isUndefined } from "lodash";

import { AppErrorProps, Dict, FormErrorProps, ResponseErrorViolationProps } from "../api/AppDTO";

export class AppError implements AppErrorProps {
  public name?: string;
  public status: number;
  public message: string;

  data: ResponseErrorViolationProps[];

  constructor(message: string, { data = [], name, status = 500 }: AppErrorProps = {}) {
    this.data = data;
    this.name = name;
    this.status = status;
    this.message = message;
  }

  public getFormikErrors(): Dict<string> {
    const errors = this.getErrors();

    return errors.reduce((acc, item) => {
      if (item.field && item.message && !acc[item.field]) {
        return update(acc, {
          [item.field]: item.message,
        });
      }

      return acc;
    }, {});
  }

  public getErrors(): FormErrorProps[] {
    if (isArray(this.data)) {
      return this.data.map(({ propertyPath, message }) => ({
        message,
        field: camelCase(propertyPath),
      }));
    } else {
      if (
        this.data &&
        // @ts-ignore
        this.data.errors &&
        // @ts-ignore
        isArray(this.data.errors) &&
        // @ts-ignore
        !isUndefined(this.data?.idx)
      ) {
        // @ts-ignore
        return this.data.errors.map(({ field, message }) => ({
          message: message,
          field: camelCase(field),
        }));
      }
    }

    return [];
  }

  public toString(): string {
    return this.getErrors()
      .map(({ message }) => message)
      .join("\n");
  }
}
