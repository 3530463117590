import React from "react";
import { makeStyles } from "@material-ui/core";

import { Loader } from "../ui/Loader";

const useStyles = makeStyles({
  root: { display: "flex", flex: 1, alignItems: "center", justifyContent: "center" },
});

export function AppLoader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Loader />
    </div>
  );
}
