import React from "react";
import cx from "classnames";
import { makeStyles, SvgIcon } from "@material-ui/core";

import { ColorPalette } from "../../constants/ColorPalette";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyle = makeStyles<Theme, { size: number }>((theme) => ({
  root: { fontSize: (props) => theme.typography.pxToRem(props.size) },
}));

interface Props {
  readonly size?: number;
  readonly className?: string;
  readonly color?: ColorPalette;
}

export function ChevronIcon({ size = 16, className, color = ColorPalette.Secondary }: Props) {
  const classes = useStyle({ size });

  return (
    <SvgIcon className={cx(classes.root, className)} viewBox="0 0 16 16">
      <path
        fill="none"
        stroke={color}
        strokeWidth="1.5"
        d="M5.3335 6.33325L8.00016 8.99992L10.6668 6.33325"
      />
    </SvgIcon>
  );
}
