import React, { ReactNode } from "react";

import { ApiProvider } from "../api/ApiContext";
import { tokenSelector } from "../reducers/AuthReducer";
import { appEnvironmentSelector } from "../reducers/AppReducer";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";

interface Props {
  readonly children: ReactNode;
}

export function ApiContainer({ children }: Props) {
  const token = useShallowEqualSelector(tokenSelector);
  const environment = useShallowEqualSelector(appEnvironmentSelector);

  return <ApiProvider data={{ token, environment }}>{children}</ApiProvider>;
}
