import { Action } from "redux";
import { DELETE, update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { TokenProps } from "../api/auth/AuthDTO";
import { AppStoreState } from "../store/RootReducer";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const authReducerPersistConfig: Partial<PersistConfig<AuthReducerState>> = {
  whitelist: ["token"],
};

interface SetAuthTokenMeta {
  readonly token: TokenProps;
  readonly remember: boolean;
}

enum ReducerActions {
  SetAuthToken = "Auth/SetAuthToken",
  ResetAuthToken = "Auth/ResetAuthToken",
}

export interface AuthReducerState {
  readonly token?: TokenProps;
  readonly temporaryToken?: TokenProps;
}

function getState(): AuthReducerState {
  return {};
}

/** ==================
 * Reducers
 * ================== */
export const authReducer = createRootReducer<AuthReducerState>(
  getState(),

  createReducer([ReducerActions.SetAuthToken], (state, { meta }) => {
    if (meta.remember) {
      return update(state, { token: meta.token });
    }

    return update(state, { temporaryToken: meta.token });
  }),

  createReducer([ReducerActions.ResetAuthToken], (state) =>
    update(state, { token: DELETE, temporaryToken: DELETE }),
  ),
);

/** ==================
 * Selectors
 * ================== */
export const isAuthorizedSelector = ({ auth }: AppStoreState): boolean =>
  Boolean(auth.token?.token || auth.temporaryToken?.token);

export const tokenSelector = ({ auth }: AppStoreState): TokenProps | undefined => auth.token;

/** ==================
 * Actions
 * ================== */
export const setAuthToken = (meta: SetAuthTokenMeta): PerformAction<SetAuthTokenMeta> => ({
  meta,
  type: ReducerActions.SetAuthToken,
});

export const resetAuthToken = (): Action => ({ type: ReducerActions.ResetAuthToken });
