import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Drawer, DrawerClassKey } from "@material-ui/core";

import { ClassNameMap } from "@material-ui/styles/withStyles/withStyles";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { changeSidebarState, sidebarStateSelector } from "../../reducers/AppReducer";

interface Props {
  readonly children: ReactNode;
  readonly classes: Partial<ClassNameMap<DrawerClassKey>>;
}

export function AppFloatingDrawerWrapper({ classes, children }: Props) {
  const sidebarState = useShallowEqualSelector(sidebarStateSelector);

  const dispatch = useDispatch();

  return (
    <Drawer
      anchor="left"
      classes={classes}
      open={sidebarState}
      PaperProps={{ component: "aside" }}
      onClose={() => dispatch(changeSidebarState({ state: false }))}
    >
      {children}
    </Drawer>
  );
}
