import React, { ReactElement, ReactNode, useContext, useMemo } from "react";

import { UserProps } from "./UserDTO";

export interface UserContextDataProps {
  readonly token?: string;
  readonly user: UserProps;
}

export interface UserContextAuthorizationProps {
  readonly loaded: boolean;
  readonly loading: boolean;
  readonly authorized: boolean;
}

export interface UserContextProps {
  readonly user: UserProps;
  readonly authorization: UserContextAuthorizationProps;
}

export interface ApiProviderProps {
  readonly children: ReactNode;
  readonly data?: UserContextDataProps;
}

function createContentValue({ token, ...userData }: UserContextDataProps): UserContextProps {
  const authorized = Boolean(token);

  return {
    ...userData,
    authorization: {
      authorized,
      loaded: true,
      loading: false,
    },
  };
}

export const UserDataContext = React.createContext<UserContextProps>(
  createContentValue({
    user: {} as UserProps,
  }),
);

export function UserProvider({
  data = {} as UserContextDataProps,
  ...props
}: ApiProviderProps): ReactElement<object> {
  const value = useMemo(() => createContentValue(data), [data]);

  return <UserDataContext.Provider {...props} value={value} />;
}

export function useUser(): UserContextProps {
  return useContext(UserDataContext);
}
