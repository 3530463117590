import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";

import { ColorPalette } from "../../constants/ColorPalette";

interface Props {
  readonly size?: number;
  readonly className?: string;
  readonly color?: ColorPalette;
}

interface StylesProps {
  readonly size: number;
  readonly color: ColorPalette;
}

const useStyle = makeStyles((theme) => ({
  root: {
    color: (props: StylesProps) => props.color,
    fontSize: (props: StylesProps) => theme.typography.pxToRem(props.size!),
  },
}));

export function DotIcon({ size = 6, className, color = ColorPalette.Success }: Props) {
  const classes = useStyle({ color, size });

  return (
    <SvgIcon className={className} classes={classes} viewBox="0 0 6 6">
      <rect width="6" height="6" rx="3" />
    </SvgIcon>
  );
}
