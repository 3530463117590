import React, { forwardRef, ReactNode } from "react";
import { makeStyles, MenuItem, MenuItemProps } from "@material-ui/core";

import { ColorPalette } from "../../constants/ColorPalette";

export interface ActionMenuItemProps<T> extends Omit<MenuItemProps, "onClick" | "button"> {
  readonly children: ReactNode;
  readonly color?: ColorPalette;
  readonly onClick: (record: T) => void;
}

const useStyle = makeStyles({
  root: {
    height: "36px",
    fontSize: "12px",
    lineHeight: "20px",
    color: (props: Partial<ActionMenuItemProps<any>>) => props.color,

    "&:not(:last-child)": {
      borderBottom: `1px solid ${ColorPalette.Gray}`,
    },
  },
});

export const ActionMenuItem = forwardRef<any, ActionMenuItemProps<any>>(
  ({ onClick, children, color = ColorPalette.Secondary, ...props }, ref) => {
    const classes = useStyle({ color });

    return (
      <MenuItem {...props} ref={ref} classes={classes} onClick={() => onClick({})}>
        {children}
      </MenuItem>
    );
  },
);
