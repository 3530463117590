import React from "react";

import { ApiContainer } from "./ApiContainer";
import { RootContainer } from "./RootContainer";
import { UserContainer } from "./UserContainer";
import { StorageContainer } from "./StorageContainer";
import { MaterialContainer } from "./MaterialContainer";

export function AppContainer() {
  return (
    <StorageContainer>
      <ApiContainer>
        <UserContainer>
          <MaterialContainer>
            <RootContainer />
          </MaterialContainer>
        </UserContainer>
      </ApiContainer>
    </StorageContainer>
  );
}
