import React from "react";

export interface Dict<T> {
  readonly [key: string]: T;
}

export interface ResponseErrorViolationProps {
  readonly message: string;
  readonly propertyPath: string;
}

export interface ResponseErrorDataProps {
  readonly title: string;
  readonly detail: string;
  readonly violations: ResponseErrorViolationProps[];
}

export interface AppErrorProps extends Omit<Error, "name" | "message"> {
  name?: string;
  status?: number;
  data?: ResponseErrorViolationProps[];
}

export interface FormErrorProps {
  readonly field: string;
  readonly message: string;
}

export enum AppEnvironment {
  Staging = "staging",
  Production = "production",
  Development = "development",
}

export enum Status {
  Active = "active",
  Inactive = "inactive",
}

export enum Gender {
  Male = "male",
  Female = "female",
  Unknown = "unknown",
}

export enum Direction {
  Top = "top",
  Left = "left",
  Right = "right",
  Bottom = "bottom",
}

export type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

export interface ContentProps {
  readonly title: string;
  readonly description: string;
}

export interface FilterProps {
  readonly page?: number;
  readonly search?: string;
  readonly per_page?: number;
  readonly pagination?: boolean;
}

export interface FilterDateProps {
  readonly after?: Date;
  readonly before?: Date;
  readonly strictlyAfter?: Date;
  readonly strictlyBefore?: Date;
}

export interface FilterStringDateProps {
  readonly after?: string;
  readonly before?: string;
  readonly strictlyAfter?: string;
  readonly strictlyBefore?: string;
}

export interface ImageProps {
  readonly id: number;
  readonly size: string;
  readonly type: string;
  readonly title: string;
  readonly filepath: string;
  readonly createdAt: string;
  readonly contentUrl: string;
}
