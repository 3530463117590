import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import React, { ReactNode, useEffect, useState } from "react";

import { AppLoader } from "../components/app/AppLoader";
import { configureStore, StoreProps } from "../store/configureStore";

interface Props {
  readonly children: ReactNode;
}

export function StorageContainer({ children }: Props) {
  const [store, setStore] = useState<StoreProps>();

  useEffect(() => {
    setStore(configureStore());
  }, []);

  if (!store?.store || !store.persistor) {
    return <AppLoader />;
  }

  return (
    <Provider store={store.store}>
      <PersistGate loading={<AppLoader />} persistor={store.persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
