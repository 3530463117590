import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";

const useStyle = makeStyles({
  root: { fontSize: "20px" },
});

export function EmployeeIcon() {
  const classes = useStyle();

  return (
    <SvgIcon classes={classes} viewBox="0 0 20 20">
      <path d="M1.667 9.99984C1.667 5.39734 5.39783 1.6665 10.0003 1.6665C14.6028 1.6665 18.3337 5.39734 18.3337 9.99984C18.3337 14.6023 14.6028 18.3332 10.0003 18.3332H1.667L4.10783 15.8923C3.33286 15.1194 2.71828 14.2009 2.2994 13.1897C1.88052 12.1784 1.6656 11.0944 1.667 9.99984ZM5.69033 16.6665H10.0003C11.3189 16.6665 12.6078 16.2755 13.7041 15.543C14.8005 14.8104 15.6549 13.7692 16.1595 12.5511C16.6641 11.3329 16.7961 9.99244 16.5389 8.69924C16.2817 7.40603 15.6467 6.21814 14.7144 5.28579C13.782 4.35344 12.5941 3.7185 11.3009 3.46127C10.0077 3.20403 8.66728 3.33606 7.44911 3.84064C6.23094 4.34522 5.18974 5.19971 4.4572 6.29604C3.72466 7.39236 3.33367 8.6813 3.33367 9.99984C3.33367 11.7932 4.04283 13.4707 5.28617 14.714L6.4645 15.8923L5.69033 16.6665ZM6.667 10.8332H13.3337C13.3337 11.7172 12.9825 12.5651 12.3574 13.1902C11.7322 13.8153 10.8844 14.1665 10.0003 14.1665C9.11628 14.1665 8.26843 13.8153 7.64331 13.1902C7.01819 12.5651 6.667 11.7172 6.667 10.8332Z" />
    </SvgIcon>
  );
}
