import React from "react";
import { Button, ButtonProps, makeStyles, Theme, useMediaQuery } from "@material-ui/core";

import { Avatar } from "../avatar/Avatar";
import { UserProps } from "../../api/user/UserDTO";
import { ColorPalette } from "../../constants/ColorPalette";
import { UserPlaceholderIcon } from "../icons/UserPlaceholderIcon";
import { ChevronIcon } from "../icons/ChevronIcon";

const useStyle = makeStyles((theme) => ({
  root: { display: "flex" },
  nameWrapper: { display: "flex" },
  container: { display: "flex", marginLeft: theme.typography.pxToRem(12), alignItems: "center" },
  nameContainer: { flexGrow: 1, flexShrink: 1, textAlign: "left" },
  position: {
    lineHeight: 1,
    fontWeight: 400,
    textAlign: "left",
    textTransform: "none",
    color: ColorPalette.Success,
    fontSize: theme.typography.pxToRem(11),
  },
  name: {
    fontWeight: 400,
    textAlign: "left",
    textTransform: "none",
    color: ColorPalette.Secondary,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
  },
}));

interface Props extends ButtonProps {
  readonly user: UserProps;
}

export function AppUserButton({ user, ...props }: Props) {
  const classes = useStyle();

  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return (
    <Button disableRipple={true} className={classes.root} {...props}>
      <Avatar icon={<UserPlaceholderIcon />} color={ColorPalette.Success} />

      {isLarge && (
        <div className={classes.container}>
          <span className={classes.position}>{user.position}</span>

          <ChevronIcon size={24} color={ColorPalette.DarkGray} />
        </div>
      )}
    </Button>
  );
}
