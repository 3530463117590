export enum ColorPalette {
  Black = "#000000",
  White = "#ffffff",

  Transparent = "rgba(0, 0, 0, 0)",

  Blue = "#6576ff",
  Aqua = "#33c0e3",
  Green = "#39cc4d",
  Yellow = "#f4bd0e",
  Orange = "#fe710b",

  LightGreen = "#E7F8E9",
  DarkBlue = "#336FE3",
  GreenAqua = "#39CCBA",
  LighterGreen = "#AAF40E",
  Purple = "#A033E3",
  Crimson = "#FE0B8E",
  DarkerBlue = "#338FE3",
  PaleGreen = "#A1EB87",
  Ocher = "#E8D847",
  Pink = "#FE0B8E",
  LightBlue = "#65FFF6",
  DarkGray = "#CCC7C1",
  Brown = "#BBA46A",

  Error = "#e85347",
  Success = "#39cc4d",
  Primary = "#101924",
  Secondary = "#7486aa",

  BlueGrayLight = "#8094ae",
  BlueGrayDark = "#364A63",
  BlueGrayExtraDark = "#1c2837",

  DarkWhite = "#d0d6dd",

  ExtraGray = "#f5f6fa",
  Gray = "#eceef4",
  GrayMedium = "#e5e5e5",

  White1 = "rgba(255, 255, 255, 0.01)",

  Black23 = "rgba(0, 0, 0, 0.23)",
  Black26 = "rgba(0, 0, 0, 0.26)",
  Black40 = "rgba(0, 0, 0, 0.40)",
}
