import React, { Suspense } from "react";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { Routes } from "../constants/Routes";
import { NotFoundPage } from "../pages/NotFoundPage";
import { AppLoader } from "../components/app/AppLoader";
import { tokenSelector } from "../reducers/AuthReducer";
import { PrivateRoute } from "../components/routing/PrivateRoute";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";

const LoginPage = React.lazy(() => import("../pages/LoginPage"));
const DashboardContainer = React.lazy(() => import("./DashboardContainer"));

export function RootContainer() {
  const token = useShallowEqualSelector(tokenSelector);

  return (
    <SnackbarProvider maxSnack={3}>
      <Suspense fallback={<AppLoader />}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute path={Routes.Dashboard} component={DashboardContainer} />

            {Boolean(token) && (
              <>
                <Redirect to={Routes.Dashboard} from="/" exact={true} />
                <Redirect to={Routes.Dashboard} from={Routes.Login} exact={true} />
              </>
            )}

            {Boolean(!token) && (
              <>
                <Route path={Routes.Login} component={LoginPage} />

                <Redirect to={Routes.Login} from="/" exact={true} />
              </>
            )}

            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </SnackbarProvider>
  );
}
