import React, { ReactNode } from "react";
import { Avatar as MUIAvatar, AvatarProps as MUIAvatarProps, makeStyles } from "@material-ui/core";

import { UserIcon } from "../icons/UserIcon";
import { ColorPalette } from "../../constants/ColorPalette";

interface StylesProps {
  readonly size: number;
  readonly color: ColorPalette;
  readonly iconColor: ColorPalette;
}

const useStyle = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    backgroundColor: (props: StylesProps) => props.color,
    width: (props: StylesProps) => theme.typography.pxToRem(props.size),
    height: (props: StylesProps) => theme.typography.pxToRem(props.size),
    borderRadius: (props: StylesProps) => theme.typography.pxToRem(props.size / 2),
  },
  icon: {
    color: (props: StylesProps) => props.iconColor,
    fontSize: (props: StylesProps) => theme.typography.pxToRem(props.size * 0.8),
  },
}));

export interface AvatarProps extends MUIAvatarProps {
  readonly size?: number;
  readonly icon?: ReactNode;
  readonly component?: Element;
  readonly color?: ColorPalette;
  readonly iconColor?: ColorPalette;
}

export function Avatar({
  size = 32,
  icon,
  color = ColorPalette.ExtraGray,
  iconColor = ColorPalette.DarkWhite,
  ...props
}: AvatarProps) {
  const { icon: iconClassName, ...classes } = useStyle({ size, color, iconColor });

  return (
    <MUIAvatar classes={classes} {...props}>
      {Boolean(!icon) && <UserIcon className={iconClassName} />}
      {icon}
    </MUIAvatar>
  );
}
