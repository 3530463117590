import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: { fontSize: theme.typography.pxToRem(20) },
}));

export function BonusIcon() {
  const classes = useStyle();

  return (
    <SvgIcon classes={classes} viewBox="0 0 20 20">
      <path d="M13.3337 10.8333V4.16667H5.00033V10.8333C5.00033 11.2754 5.17592 11.6993 5.48848 12.0118C5.80104 12.3244 6.22497 12.5 6.66699 12.5H11.667C12.109 12.5 12.5329 12.3244 12.8455 12.0118C13.1581 11.6993 13.3337 11.2754 13.3337 10.8333ZM4.16699 2.5H16.667C17.109 2.5 17.5329 2.67559 17.8455 2.98816C18.1581 3.30072 18.3337 3.72464 18.3337 4.16667V6.66667C18.3337 7.10869 18.1581 7.53262 17.8455 7.84518C17.5329 8.15774 17.109 8.33333 16.667 8.33333H15.0003V10.8333C15.0003 11.7174 14.6491 12.5652 14.024 13.1904C13.3989 13.8155 12.551 14.1667 11.667 14.1667H6.66699C5.78294 14.1667 4.93509 13.8155 4.30997 13.1904C3.68485 12.5652 3.33366 11.7174 3.33366 10.8333V3.33333C3.33366 3.11232 3.42146 2.90036 3.57774 2.74408C3.73402 2.5878 3.94598 2.5 4.16699 2.5ZM15.0003 4.16667V6.66667H16.667V4.16667H15.0003ZM1.66699 15.8333H16.667V17.5H1.66699V15.8333Z" />
    </SvgIcon>
  );
}
