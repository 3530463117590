import { DateTime } from "luxon";
import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { AppStoreState } from "../store/RootReducer";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";
import { ChartDatesActionsFilterProps } from "../components/analytics/ChartDatesActions";

export const analyticsReducerPersistConfig: Partial<PersistConfig<AnalyticsReducerState>> = {
  whitelist: ["token"],
};

interface ChangeSalesFilterMeta {
  readonly filter: ChartDatesActionsFilterProps;
}

interface ChangeRegisterFilterMeta {
  readonly filter: ChartDatesActionsFilterProps;
}

enum ReducerActions {
  ChangeSalesFilter = "Analytics/ChangeSalesFilter",
  ChangeRegisterFilter = "Analytics/ChangeRegisterFilter",
}

export interface AnalyticsReducerState {
  readonly salesFilter: ChartDatesActionsFilterProps;
  readonly registerFilter: ChartDatesActionsFilterProps;
}

function getState(): AnalyticsReducerState {
  return {
    salesFilter: {
      toDate: DateTime.local().endOf("day").toJSDate(),
      fromDate: DateTime.local().startOf("month").toJSDate(),
    },
    registerFilter: {
      toDate: DateTime.local().endOf("day").toJSDate(),
      fromDate: DateTime.local().startOf("month").toJSDate(),
    },
  };
}

/** ==================
 * Reducers
 * ================== */
export const analyticsReducer = createRootReducer<AnalyticsReducerState>(
  getState(),

  createReducer([ReducerActions.ChangeSalesFilter], (state, { meta }) =>
    update(state, { salesFilter: meta.filter }),
  ),

  createReducer([ReducerActions.ChangeRegisterFilter], (state, { meta }) =>
    update(state, { registerFilter: meta.filter }),
  ),
);

/** ==================
 * Selectors
 * ================== */
export const salesFilter = ({ analytics }: AppStoreState): ChartDatesActionsFilterProps =>
  analytics.salesFilter;

export const registerFilter = ({ analytics }: AppStoreState): ChartDatesActionsFilterProps =>
  analytics.registerFilter;

/** ==================
 * Actions
 * ================== */
export const changeSalesFilter = (
  meta: ChangeSalesFilterMeta,
): PerformAction<ChangeSalesFilterMeta> => ({ type: ReducerActions.ChangeSalesFilter, meta });

export const changeRegisterFilter = (
  meta: ChangeRegisterFilterMeta,
): PerformAction<ChangeRegisterFilterMeta> => ({ type: ReducerActions.ChangeRegisterFilter, meta });
