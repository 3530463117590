import React from "react";
import cx from "classnames";
import { CircularProgress, makeStyles } from "@material-ui/core";

import { ColorPalette } from "../../constants/ColorPalette";

interface StylesProps {
  readonly size: number;
  readonly color: ColorPalette;
}

const useStyle = makeStyles({
  progress: {
    color: (props: StylesProps) => props.color,
  },
});

interface Props {
  readonly size?: number;
  readonly className?: string;
  readonly color?: ColorPalette;
}

export function Loader({ size = 24, color = ColorPalette.Success, className }: Props) {
  const classes = useStyle({ size, color });

  return (
    <CircularProgress
      size={size}
      data-testid="LoaderTestId"
      className={cx(classes.progress, className)}
    />
  );
}
