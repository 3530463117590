import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { isAuthorizedSelector } from "../../reducers/AuthReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";

interface Props extends Omit<RouteProps, "component"> {
  readonly component: React.ComponentType<RouteProps>;
}

export function PrivateRoute({ component, ...props }: Props) {
  const isAuthorized = useShallowEqualSelector(isAuthorizedSelector);

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (isAuthorized) {
          return React.createElement(component, routeProps);
        }

        return <Redirect to="/" />;
      }}
    />
  );
}
