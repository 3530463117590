import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  root: {
    fontSize: "20px",
  },
});

export function ShopIcon() {
  const classes = useStyle();

  return (
    <SvgIcon classes={classes} viewBox="0 0 20 20">
      <path d="M10.8333 15.8336H15.8333V8.31523L10 3.77856L4.16667 8.31523V15.8336H9.16667V10.8336H10.8333V15.8336ZM17.5 16.6669C17.5 16.8879 17.4122 17.0999 17.2559 17.2562C17.0996 17.4124 16.8877 17.5002 16.6667 17.5002H3.33333C3.11232 17.5002 2.90036 17.4124 2.74408 17.2562C2.5878 17.0999 2.5 16.8879 2.5 16.6669V7.90856C2.49991 7.78157 2.52885 7.65624 2.5846 7.54214C2.64035 7.42804 2.72143 7.3282 2.82167 7.25023L9.48833 2.06523C9.63462 1.95143 9.81467 1.88965 10 1.88965C10.1853 1.88965 10.3654 1.95143 10.5117 2.06523L17.1783 7.25023C17.2786 7.3282 17.3597 7.42804 17.4154 7.54214C17.4712 7.65624 17.5001 7.78157 17.5 7.90856V16.6669Z" />
    </SvgIcon>
  );
}
