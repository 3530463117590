import { toFinite } from "lodash";
import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { AppStoreState } from "../store/RootReducer";
import { DocumentProps } from "../api/document/DocumentDTO";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const documentReducerPersistConfig: Partial<PersistConfig<DocumentReducerState>> = {
  whitelist: [],
};

interface SetDocumentListMeta {
  readonly list: DocumentProps[];
}

enum ReducerActions {
  SetDocumentList = "Document/SetDocumentList",
}

export interface DocumentReducerState {
  readonly list: DocumentProps[];
}

function getState(): DocumentReducerState {
  return {
    list: [],
  };
}

/** ==================
 * Reducers
 * ================== */
export const documentReducer = createRootReducer<DocumentReducerState>(
  getState(),

  createReducer([ReducerActions.SetDocumentList], (state, { meta }) =>
    update(state, { list: meta.list }),
  ),
);

/** ==================
 * Selectors
 * ================== */
export const documentsListSelector = ({ document }: AppStoreState): DocumentProps[] =>
  document.list || [];

export const documentByIdSelector = (id: number | string) => ({
  document,
}: AppStoreState): DocumentProps =>
  document.list.find((x) => x.id === toFinite(id)) || ({} as DocumentProps);

/** ==================
 * Actions
 * ================== */
export const setDocumentList = (meta: SetDocumentListMeta): PerformAction<SetDocumentListMeta> => ({
  type: ReducerActions.SetDocumentList,
  meta,
});
