import React from "react";
import { Drawer, makeStyles, Toolbar, useMediaQuery } from "@material-ui/core";

import { AppMenu } from "./AppMenu";
import { ColorPalette } from "../../constants/ColorPalette";
import { AppFloatingDrawerWrapper } from "./AppFloatingDrawerWrapper";

const useStyles = makeStyles((theme) => ({
  root: { flexShrink: 0, width: theme.typography.pxToRem(290) },
  paper: { width: theme.typography.pxToRem(290), backgroundColor: ColorPalette.Primary },
}));

export function AppSidebar() {
  const classes = useStyles();

  const isSmall = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return (
      <AppFloatingDrawerWrapper classes={classes}>
        <AppMenu />
      </AppFloatingDrawerWrapper>
    );
  }

  return (
    <Drawer PaperProps={{ component: "aside" }} anchor="left" classes={classes} variant="permanent">
      <Toolbar />

      <AppMenu />
    </Drawer>
  );
}
