import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { AppEnvironment } from "../api/AppDTO";
import { IS_DEV } from "../constants/AppConstants";
import { AppStoreState } from "../store/RootReducer";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const appReducerPersistConfig: Partial<PersistConfig<AppReducerState>> = {
  whitelist: ["environment"],
};

interface ChangeEnvironmentMeta {
  readonly environment: AppEnvironment;
}

interface ChangeSidebarStateMeta {
  readonly state: boolean;
}

enum ReducerActions {
  ChangeEnvironment = "App/ChangeEnvironment",
  ChangeSidebarState = "App/ChangeSidebarState",
}

export interface AppReducerState {
  readonly sidebarState: boolean;
  readonly environment: AppEnvironment;
}

function getState(): AppReducerState {
  return {
    sidebarState: false,
    environment: AppEnvironment.Production,
  };
}

/** ==================
 * Reducers
 * ================== */
export const appReducer = createRootReducer<AppReducerState>(
  getState(),

  createReducer([ReducerActions.ChangeEnvironment], (state, { meta }) =>
    update(state, { environment: meta.environment }),
  ),

  createReducer([ReducerActions.ChangeSidebarState], (state, { meta }) =>
    update(state, { sidebarState: meta.state }),
  ),
);

/** ==================
 * Selectors
 * ================== */
export const appEnvironmentSelector = (state: AppStoreState): AppEnvironment => {
  if (IS_DEV) {
    return AppEnvironment.Development;
  }

  return state.app.environment;
};

export const sidebarStateSelector = ({ app }: AppStoreState): boolean => app.sidebarState;

/** ==================
 * Actions
 * ================== */
export const changeEnvironment = (
  meta: ChangeEnvironmentMeta,
): PerformAction<ChangeEnvironmentMeta> => ({ type: ReducerActions.ChangeEnvironment, meta });

export const changeSidebarState = (
  meta: ChangeSidebarStateMeta,
): PerformAction<ChangeSidebarStateMeta> => ({ type: ReducerActions.ChangeSidebarState, meta });
