export enum Routes {
  Login = "/login",

  Dashboard = "/dashboard",

  Employees = "/dashboard/employees",
  EmployeeEdit = "/dashboard/employees/edit/:id",
  EmployeeCreate = "/dashboard/employees/create",

  Shops = "/dashboard/shops",
  ShopEdit = "/dashboard/shops/edit/:id",
  ShopCreate = "/dashboard/shops/create",

  LoyaltyPrograms = "/dashboard/loyalty-programs",
  LoyaltyProgramEdit = "/dashboard/loyalty-programs/edit/:id",
  LoyaltyProgramCreate = "/dashboard/loyalty-programs/create",

  Clients = "/dashboard/clients",
  ClientEdit = "/dashboard/clients/edit/:id",
  ClientTransactionsList = "/dashboard/clients/edit/:id/transactions/list",
  ClientArchiveProgramsList = "/dashboard/clients/edit/:id/archive-programs/list",
  ClientLoyaltyProgramsList = "/dashboard/clients/edit/:id/loyalty-programs/list",
  ClientLoyaltyProgramsItem = "/dashboard/clients/edit/:id/loyalty-programs/item",

  News = "/dashboard/news",
  NewsEdit = "/dashboard/news/edit/:id",
  NewsCreate = "/dashboard/news/create",

  Promotions = "/dashboard/promotions",
  PromotionEdit = "/dashboard/promotions/edit/:id",
  PromotionCreate = "/dashboard/promotions/create",

  Documents = "/dashboard/documents",
  Analytics = "/dashboard/analytics",
  Transactions = "/dashboard/transactions",
}
