import { toFinite } from "lodash";

import { Logger } from "./Logger";
import { tryStringifyJSON } from "./DataUtils";

const logger = new Logger("API");

const PAGINATION_PAGE_HEADER = "x-current-page";
const PAGINATION_PER_PAGE_HEADER = "x-items-perpage";
const PAGINATION_PAGE_COUNT_HEADER = "x-total-pages";
const PAGINATION_TOTAL_COUNT_HEADER = "x-total-items";

export function apiLogger(request: Request) {
  logger.log(`================ ${request.method} ================`);
  logger.log(`Url -> ${request.url}`);
  logger.log(`Headers -> ${tryStringifyJSON(request.headers)}`);
  logger.log(`====================================`);
}

export interface HeaderPaginationProps {
  readonly page: number;
  readonly perPage: number;
  readonly pageCount: number;
  readonly totalCount: number;
}

export function getPaginationFromHeaders(headers: Headers): HeaderPaginationProps {
  const page = toFinite(headers.get(PAGINATION_PAGE_HEADER));
  const perPage = toFinite(headers.get(PAGINATION_PER_PAGE_HEADER));
  const pageCount = toFinite(headers.get(PAGINATION_PAGE_COUNT_HEADER));
  const totalCount = toFinite(headers.get(PAGINATION_TOTAL_COUNT_HEADER));

  return {
    page,
    perPage,
    pageCount,
    totalCount,
  };
}

export interface PaginatorProps {
  readonly page?: number;
  readonly perPage?: number;
}

export function setPaginationToQuery(
  paginator: PaginatorProps = {} as PaginatorProps,
  headers: Headers | string[][] | Record<string, string> = [],
): string[][] {
  const { page = 1, perPage = 20 } = paginator;

  return [
    ...(headers as any),
    ...[
      [PAGINATION_PAGE_HEADER, page],
      [PAGINATION_PER_PAGE_HEADER, perPage],
    ],
  ];
}
