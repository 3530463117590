import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";

import { ColorPalette } from "../../constants/ColorPalette";

interface StylesProps {
  readonly size: number;
}

const useStyle = makeStyles((theme) => ({
  root: {
    fontSize: (props: StylesProps) => theme.typography.pxToRem(props.size),
  },
}));

interface Props {
  readonly size?: number;
  readonly className?: string;
  readonly color?: ColorPalette;
}

export function UserIcon({ size = 24, color = ColorPalette.DarkWhite, className }: Props) {
  const classes = useStyle({ size });

  return (
    <SvgIcon fill="none" className={className} classes={classes} viewBox="0 0 56 64">
      <path
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.9391 13.3125L21.5871 10.566C21.8808 10.0764 22.5231 9.93195 23.0126 10.2258C26.2894 12.1924 32.465 14.9044 36.71 13.3125M18.8149 34.4583C21.0493 37.5634 24.3312 40.2917 28.6884 40.2917C33.0457 40.2917 36.3276 37.5634 38.562 34.4583M18.8149 34.4583C17.2577 32.2944 16.2093 29.9473 15.6604 28.2132C15.5953 28.0076 15.4704 27.8282 15.3027 27.6926C13.3503 26.1149 10.4387 22.5231 12.6198 19.1867C12.6373 19.16 12.7024 19.0909 12.7255 19.0687V19.0687C13.095 18.7137 14.7162 17.7244 14.8124 17.2211C15.0485 15.9851 14.8671 14.521 14.1058 11.8542C13.383 9.68577 14.0918 3.21449 21.2072 3.10359C21.33 3.10167 21.4521 3.08058 21.5671 3.03746C27.9345 0.650382 41.0327 -0.85809 43.9751 11.74C43.9921 11.8126 44.0017 11.8909 43.9988 11.9654C43.9777 12.5235 43.7997 12.8852 43.546 13.1102C42.9556 13.6338 42.0639 14.3143 42.2553 15.0798L43.1056 18.4811C43.2058 18.8817 43.5494 19.1672 43.928 19.3322C45.7559 20.1288 47.6134 23.2456 42.0712 27.6922C41.9077 27.8234 41.7826 28.004 41.7195 28.2038C41.1713 29.9389 40.122 32.2905 38.562 34.4583M18.8149 34.4583C18.8149 34.4583 14.835 36.6458 8.27246 41.0208C1.70996 45.3958 1.7099 46.125 1.70996 55.6042L1.70996 62.1667M38.562 34.4583C40.1319 35.4306 42.5433 36.9198 49.1058 41.0208C55.6683 45.1219 54.9391 48.3125 54.9391 55.6042C54.9391 58.167 54.9391 62.1667 54.9391 62.1667"
      />
    </SvgIcon>
  );
}
