import React from "react";
import { makeStyles, SvgIcon } from "@material-ui/core";

import { ColorPalette } from "../../constants/ColorPalette";

interface StylesProps {
  readonly size: number;
}

const useStyle = makeStyles((theme) => ({
  root: { fontSize: (props: StylesProps) => theme.typography.pxToRem(props.size) },
}));

interface Props {
  readonly size?: number;
  readonly className?: string;
  readonly color?: ColorPalette;
}

export function UserPlaceholderIcon({ size = 16, color = ColorPalette.White, className }: Props) {
  const classes = useStyle({ size });

  return (
    <SvgIcon fill="none" className={className} classes={classes} viewBox="0 0 16 16">
      <path
        fill={color}
        d="M2.66699 14.6665C2.66699 13.252 3.2289 11.8955 4.22909 10.8953C5.22928 9.89507 6.58584 9.33317 8.00033 9.33317C9.41481 9.33317 10.7714 9.89507 11.7716 10.8953C12.7718 11.8955 13.3337 13.252 13.3337 14.6665H12.0003C12.0003 13.6056 11.5789 12.5882 10.8288 11.8381C10.0786 11.0879 9.06119 10.6665 8.00033 10.6665C6.93946 10.6665 5.92204 11.0879 5.1719 11.8381C4.42175 12.5882 4.00033 13.6056 4.00033 14.6665H2.66699ZM8.00033 8.6665C5.79033 8.6665 4.00033 6.8765 4.00033 4.6665C4.00033 2.4565 5.79033 0.666504 8.00033 0.666504C10.2103 0.666504 12.0003 2.4565 12.0003 4.6665C12.0003 6.8765 10.2103 8.6665 8.00033 8.6665ZM8.00033 7.33317C9.47366 7.33317 10.667 6.13984 10.667 4.6665C10.667 3.19317 9.47366 1.99984 8.00033 1.99984C6.52699 1.99984 5.33366 3.19317 5.33366 4.6665C5.33366 6.13984 6.52699 7.33317 8.00033 7.33317Z"
      />
    </SvgIcon>
  );
}
