import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";

import { AuthApi } from "./AuthApi";
import { useApiBase } from "../ApiContext";
import { resetAuthToken } from "../../reducers/AuthReducer";

export interface AuthContextProps {
  readonly AuthApi: AuthApi;

  readonly logout: () => void;
}

export function useAuthContext(): AuthContextProps {
  const data = useApiBase();

  const dispatch = useDispatch();

  const api = useMemo(() => new AuthApi(data), [data]);

  const logout = useCallback(() => {
    dispatch(resetAuthToken());
  }, [dispatch]);

  return {
    AuthApi: api,

    logout,
  };
}
